import './Navbar.css'; // Import a CSS file for styling

function Navbar() {


    return (
        <nav className="navbar">
            <div className="navbar-logo">Cakoni Agro Garancia</div>
            <button
                className="logout-button"
                onClick={() => {
                    // Clear the token from localStorage or cookies
                    localStorage.removeItem("authToken");
                    // Redirect the user to the login page
                    window.location.href = "/";
                }}
            >
                DIL
            </button>
        </nav>
    );

}

export default Navbar;
