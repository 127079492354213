import config from "../config/config";

const warrantyService = {
  getAllWarranties: async () => {
    try {
      const response = await fetch(`${config.BASE_URL}${config.ENDPOINTS.GET_ALL}`);
      const contentType = response.headers.get('content-type');
      //console.log("\nRAW RESPONSE: ", response);
      if(contentType && contentType.includes('application/json')) {
        return await response.json();
      }
      else{
        const text = await response.text();
        throw new Error('Unexpected reponse: ', text);
      }
    } catch (error) {
      console.error('Error fetching warranties:', error);
      throw error;
    }
  },

  addWarranty: async (warrantyData) => {
    try {
      const response = await fetch(`${config.BASE_URL}${config.ENDPOINTS.ADD_WARRANTY}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(warrantyData),
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      return await response.json();
    } catch (error) {
      console.error('Error adding warranty:', error);
      throw error;
    }
  }
};

export default warrantyService;