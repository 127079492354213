import React, { useState } from "react";
import authService from "./services/authService";
import './login.css';

function LoginPage({ onLoginSuccess }) {
  const [formData, setFormData] = useState({ username: "", password: "" });
  const [error, setError] = useState(null);
  const [isLogin, setIsLogin] = useState(true); // Always true to disable switching

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isLogin) {
        // Login logic
        const data = await authService.login(formData.username, formData.password);
        onLoginSuccess(data); // Notify parent component with auth data
      }
      // else {
      //   // Registration logic (disabled)
      //   await authService.register(formData.username, formData.password);
      //   alert("Registration successful! You can now log in.");
      //   setIsLogin(true);
      // }
    } catch (err) {
      setError(err.message);
    }
  };

  // const toggleForm = () => {
  //   setIsLogin(!isLogin); // Disable toggling
  //   setFormData({ username: "", password: "" }); // Clear form data
  //   setError(null); // Clear errors
  // };

  return (
      <div className="login-page">
        <div className="login-container">
          <h2>Login</h2>
          <form onSubmit={handleSubmit}>
            <label>
              Username:
              <input
                  autoComplete="one-time-code"
                  type="text"
                  name="username"
                  value={formData.username}
                  onChange={handleInputChange}
                  required
              />
            </label>
            <label>
              Password:
              <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  required
              />
            </label>
            {error && <p className="error">{error}</p>}
            <button type="submit">Login</button>
          </form>
          {/* <button className="toggle-button" onClick={toggleForm}>
          {isLogin ? "Switch to Register" : "Switch to Login"}
        </button> */}
        </div>
      </div>
  );
}

export default LoginPage;
