import React, { useState, useEffect, useMemo } from "react";
import './MainApp.css';
import Navbar from './components/Navbar';
import SearchBar from './components/Searchbar';
import warrantyService from './services/warrantyService';

function MainApp({ user }) {
    const [searchQuery, setSearchQuery] = useState("");
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [showForm, setShowForm] = useState(false);

    const initialFormState = {
        name: "",
        surname: "",
        phone: "",
        code: "",
        product: "",
        warranty: "",
        date: new Date().toISOString().split("T")[0],
        notes: "",
    };

    const [formData, setFormData] = useState(initialFormState);

    useEffect(() => {
        const fetchWarranties = async () => {
            try {
                const data = await warrantyService.getAllWarranties();
                if (data.success && Array.isArray(data.results)) {
                    setData(data.results);
                }
            } catch (error) {
                setError("Probleme me serverin, provo me vone ose merr ne cel Aldon");
                setData([]);
            }
        };
        fetchWarranties();
    }, []);

    const handleCreateClick = () => {
        setShowForm(true);
    };

    const validateForm = () => {
        const phoneRegex = /^[0-9]{10}$/;
        if (!phoneRegex.test(formData.phone)) {
            showToast('Numri i telefonit gabim');
            return false;
        }
        return true;
    };

    function showToast(message) {
        const toast = document.createElement('div');
        toast.className = 'toast';
        toast.textContent = message;

        document.body.appendChild(toast);

        // Show the toast
        setTimeout(() => {
            toast.classList.add('show');
        }, 100);

        // Remove the toast after 3 seconds
        setTimeout(() => {
            toast.classList.remove('show');
            setTimeout(() => toast.remove(), 300);
        }, 3000);
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) return;
        setShowForm(false);
        setFormData(initialFormState);

        try {
            const result = await warrantyService.addWarranty(formData);
            if (result.success) {
                setFormData(initialFormState);
                setShowForm(false);
                const updatedData = await warrantyService.getAllWarranties();
                if (updatedData.success && Array.isArray(updatedData.results)) {
                    setData(updatedData.results);
                }
            }
        } catch (error) {
            setError(error.message);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSearch = (query) => {
        setSearchQuery(query);
    };

    const getFilteredData = () => {
        if (searchQuery.trim() === "") {
            return data;
        }
        const query = searchQuery.trim().toLowerCase();
        return data.filter((item) =>
            ["firstname", "lastname", "productCode", "productName", "phone"]
                .map((key) => item[key]?.toLowerCase() || "")
                .some((value) => value.includes(query))
        );
    };

    function formatDate(apiDate) {
        const date = new Date(apiDate);

        // Extract day, month, and year
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const year = String(date.getFullYear()).slice(-2); // Get the last 2 digits of the year

        // Format as "DD/MM/YY"
        return `${day}/${month}/${year}`;
    }

    const filteredData = useMemo(() => getFilteredData(), [searchQuery, data]);

    return (
        <div className="App">
            <Navbar />
            <header className="App-header">
                <SearchBar onSearch={handleSearch} onCreateButtonClick={handleCreateClick} />
            </header>

            {showForm && (
                <div className="modal">
                    <div className="modal-content">
                        <h2>Create Warranty</h2>
                        <form onSubmit={handleFormSubmit}>
                            {/* Form Fields */}
                            <label>
                                Emri:
                                <input
                                    autoComplete="one-time-code"
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Mbiemri:
                                <input
                                    autoComplete="one-time-code"
                                    type="text"
                                    name="surname"
                                    value={formData.surname}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Tel:
                                <input
                                    autoComplete="one-time-code"
                                    type="text"
                                    name="phone"
                                    value={formData.phone}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Kodi:
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="code"
                                    value={formData.code}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Produkti
                                <input
                                    autoComplete="off"
                                    type="text"
                                    name="product"
                                    value={formData.product}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Garanci
                                <input
                                    autoComplete="off"
                                    type="number"
                                    name="warranty"
                                    value={formData.warranty}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Data
                                <input
                                    autoComplete="off"
                                    type="date"
                                    name="date"
                                    value={formData.date}
                                    onChange={handleInputChange}
                                    required
                                />
                            </label>
                            <label>
                                Shenime
                                <textarea
                                    autoComplete="off"
                                    name="notes"
                                    value={formData.notes}
                                    onChange={handleInputChange}
                                />
                            </label>
                            <div className="form-buttons">
                                <button type="submit">Submit</button>
                                <button type="button" onClick={() => setShowForm(false)}>
                                    Cancel
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            <div className="table-container">
                <h1>Garancite</h1>
                {filteredData.length > 0 ? (
                    <table>
                        <thead>
                        <tr>
                            <th>#</th>
                            <th>Emri</th>
                            <th>Mbiemri</th>
                            <th>Kodi</th>
                            <th>Produkti</th>
                            <th>Telefon</th>
                            <th>Garanci</th>
                            <th>Data</th>
                            <th>Shenime</th>
                        </tr>
                        </thead>
                        <tbody>
                        {filteredData.map((item, index) => (
                            <tr key={item.CustomerId}>
                                <td>{index + 1}</td>
                                <td>{item.firstname}</td>
                                <td>{item.lastname}</td>
                                <td>{item.productCode}</td>
                                <td>{item.productName || "N/A"}</td>
                                <td>{item.phone}</td>
                                <td>{item.warrantyTime}</td>
                                <td>{formatDate(item.warrantyDate)}</td>
                                <td>{item.notes}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                ) : (
                    <p>Ska garanci</p>
                )}
                {error && <div className="error-message">{error}</div>}
            </div>
        </div>
    );
}

export default MainApp;
