import React from 'react';
import './searchbar.css'; // Import the CSS file for styling
import { useState } from "react";

function SearchBar({onSearch, onCreateButtonClick }) {

  const [query, setQuery] = useState("");

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      onSearch(query); // Trigger the search function passed as a prop
    }
  };


  return (
    <div className="search-container">
       <div className="search-bar">
        <label htmlFor="search-input">Kërko:</label>
        <input
          autoComplete="off"
          type="text"
          id="search-input"
          value={query}
          onChange={(e) => setQuery(e.target.value)} // Update state on input
          onKeyDown={handleKeyDown} // Trigger search on Enter key
        />
      </div>
      <button onClick={onCreateButtonClick} className="create-button">Krijo</button>
    </div>




  );
}

export default SearchBar;
