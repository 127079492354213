import React, { useState, useEffect } from "react";
import LoginPage from "./Login";
import MainApp from './MainApp';
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";

function App() {
  const [user, setUser] = useState(null);

  // Handle login success
  const handleLoginSuccess = (userData) => {
    setUser(userData);
    localStorage.setItem("authToken", userData.token);
  };

  // Check for existing authentication token
  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      setUser({ username: "Authenticated User", token });
    }
  }, []);

  // Redirect to login page if not authenticated
  if (!user) {
    return <LoginPage onLoginSuccess={handleLoginSuccess} />;
  }

  return (
      <Router>
        <Routes>
          <Route
              path="/"
              element={
                user ? <Navigate to="/main" /> : <LoginPage onLoginSuccess={handleLoginSuccess} />
              }
          />
          <Route
              path="/main"
              element={
                user ? <MainApp user={user} /> : <Navigate to="/" />
              }
          />
        </Routes>
      </Router>
  );
}
export default App;
