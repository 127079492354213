import config from "../config/config";

const authService = {
  // Register a new user
  register: async (username, password) => {
    try{

      const response = await fetch(`${config.BASE_URL}${config.ENDPOINTS.REGISTER}`, {
        method: "POST",
        mode: "cors",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ username, password }),
      });
      console.log("Raw response: ", response );
      if (!response.ok) {
        const errorDetails = await response.json(); // Parse error response from server
        throw new Error(`Error: ${response.status} - ${errorDetails.error}`);
      }
      return await response.json();
    }catch(error){
      console.error('Error registering user:', error);
      throw error;
    }
  },

  // Login existing user
  login: async (username, password) => {
    const response = await fetch(`${config.BASE_URL}${config.ENDPOINTS.LOGIN}`, {
      method: "POST",
      mode: "cors",
      credentials: "include",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ username, password }),
    });
    const data = await response.json();
    console.log("RESPONSE FROM SERVER", data);
    if (!response.ok) {
      throw new Error(data.error || "Invalid credentials");
    }
    return data; // Should return a JWT or session token
  },

  logout: async () => {
    try {
      const response = await fetch(`${config.BASE_URL}${config.ENDPOINTS.LOGOUT}`, {
        method: "POST", // or GET if your backend supports it
        mode: "cors",
        credentials: "include", // Ensures cookies are sent with the request
      });

      if (!response.ok) {
        const errorDetails = await response.json();
        throw new Error(`Error: ${response.status} - ${errorDetails.error}`);
      }

      return await response.json(); // Or handle the response as needed
    } catch (error) {
      console.error('Error logging out:', error);
      throw error;
    }
  },
};

export default authService;
